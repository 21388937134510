body{
  /*COLORS*/
  --primary-color:#80CBC4;
  --secondary-color:#80adcb;
  --tetriary-color:#80cb9e; 
  --black-:#080808;
  --white-:#F8F8F8; 
  --grey-:#cdcdcd; 
  --darkgrey-:#575757;
  --gradient-color: linear-gradient(50deg, rgba(255,252,216,0) 0%, rgba(255,252,216,0.8) 100%);
  --circle-gradient-white:radial-gradient(circle, rgba(240,240,240,0) 0%, rgba(240,240,240,0) 60%, rgba(248,248,248,1) 90%);

  background-color:var(--grey-);
  min-height:100vh; 
  margin:0; 
  padding:0; 

  font-family:Roboto, sans-serif; 
  font-weight:100; 
}

body p{
  line-height:32px; 
  font-size:16px;
  font-weight:300; 
}

h1, h3, h5{
  font-weight:400; 
}

.contentWrapper{
  width:960px; 
  margin:0px auto; 
  position:relative; 
}

a{
  text-decoration:none !important; 
  color:var(--black-);
}

.clearFix
{
  clear:both; 
}

.spacer{
  display:block;
  height:40px; 
}

.spacer_small{
  display:block;
  height:20px; 
}

hr{
  border: 1px solid var(--grey-);
  margin:20px 0; 
}

.btn{
  padding:10px 20px; 
  background-color:var(--tetriary-color);
  float:right; 
  color:var(--white-);
  cursor:pointer;
  font-size:16px;
  font-weight:300; 
  margin:10px; 
}

ul{
  margin:0;
  list-style-type:none;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

/*--------------GLOW-----------------*/

.glow, .glow_subtle{
  position:relative; 
}

.glow::after, .glow_subtle::after{
  content:'';
  position:absolute; 
  width:100%; 
  top:0; 
  bottom:0; 
  left:0; 
  right:0; 
  background: var(--gradient-color);
  pointer-events: none;
}

.glow_subtle::after{
  opacity:70%; 
}


/*--------------HEADER---------------*/

.header{
  width:100%; 
  height: 80px; 
  background-color:var(--white-);
  overflow:hidden; 
  font-weight:400; 
}

.contentWrapper_header{
  padding:5px 0; 
}

.header h1, .header h5{
    margin: 0;   
    font-weight:300;
}

.header h1{
  margin-top:5px; 
}

.header h1 span{
  display:block; 
  float:left; 
  margin-right:8px;
}

.header h5
{
  clear:left; 
}

.header .textWrapper
{
  float:left; 
}

.header h1 span::first-letter, .header h5::first-letter{
  font-weight:500; 
}

.header a{
  text-decoration:none;
  color:var(--black-);
}
/*--------------Nav---------------*/
.nav{
  min-height:60px; 
  width:100%; 
  background-color:var(--primary-color);
  position:relative; 
}

.nav_colorbar
{
  width:100%;
  height:15px;
  background-color:var(--secondary-color); 
}

.nav ul
{
  margin:0; 
  padding:0; 
  list-style-type:none; 
  min-height:60px;
  position:relative; 
}

.nav ul li a{
  text-decoration:none;
  color:var(--white-);
  float:left; 
  padding:5px 10px; 
  cursor:pointer;
  margin-right:20px; 
  height:40px; 
  line-height:30px; 
  margin-top:10px;
  font-weight:400; 
  font-size:18px; 
  box-sizing:border-box;
}

/*-------------Content------------------*/

.Content
{
  background-color:var(--white-);
  width: 640px; 
  margin-right:20px; 
  float:left; 
  min-height:420px; 
  padding-bottom:20px;
  position:relative;
}

.Content, .Aside_Behandlinger{
  margin-top:40px !important; 
}

.Content, .Aside
{
  padding:20px; 
  box-sizing:border-box; 
}

.Content p{
  margin:40px 20px; 
}

.Content img{
  width:100%;
  height:auto;
}

/*-------------HJEM------------------*/
.Banner{
  width:100%; 
  height:400px; 
  box-sizing:border-box; 
  background-image:url('/src/IMG/Banner.jpg');
  background-size:fill; 
  position:relative; 
}

.Banner h1, .Banner h3{
  color:var(--white-);
  font-weight:600; 
  width:70%; 
  position:absolute; 
  right:40px; 
  bottom:40px; 
}

.Banner h1.Align_top{
  top:40px;
}

.GMap iframe{
  width:100%; 
  box-sizing:border-box; 
  height:320px; 
}

/*-------------Behandlinger---------------*/
.price{
  padding:10px 20px; 
  background-color:var(--tetriary-color);
  float:right; 
  color:var(--white-);
  font-size:16px;
  font-weight:300; 
}

/*-------------Kontakt------------------*/
.Content .DinFodPlejer
{
  width:100%; 
  float:right; 
  margin-bottom:20px; 
}

.Content .DinFodPlejer h1 span
{
  display:block;
  float:left; 
}

.Content .DinFodPlejer h5
{
  clear:left; 
}

.Content .DinFodPlejer h1 span::first-letter, .Content .DinFodPlejer h5::first-letter{
  font-weight:500; 
}

.Content .DinFodPlejer .textWrapper{
  width:50%; 
  float:left; 
  padding-left:40px; 
}

.Content .DinFodPlejer img{
  width:120px; 
  height:120px; 
  border-radius:50%; 
  float:right; 
  margin-right:40px; 
}

.Content .contactInfo{
  clear:both; 
}

.Content .contactInfo li{
  margin: 10px 0; 
  font-weight:300;  
}

.Content .GMap{
  margin-top:40px; 
}

/*--------------Asides------------------*/
.Aside{
  background-color:var(--white-);
  width: 300px; 
  margin-top:20px; 
  float:right; 
  clear:right; 
  min-height:320px; 
}

.Aside p{
  line-height:28px; 
}

.Aside_Behandlinger{
  padding-bottom:20px;
}

.Aside_Behandlinger .Banner{
  height:160px; 
  background-image:url('./IMG/happyfeet.jpg');
}

.Aside_Behandlinger h3
{
  font-size:18px; 
  margin:40px 0; 
}

.Aside_Behandlinger h5{
  margin-bottom:0;
  font-size:16px; 
}

.Aside .btn{
  margin-top:0; 
}

.Aside.Socials{
  height:auto;
  min-height:auto;
}

.Socials h3{
  margin-bottom:10px;
}

.Socials img{
  width:100%; 
  height:auto;
  position:aboslute;
  z-index:1;
}

.fb-like{
  float:right;
  margin-top:10px; 
}
/*----------Contact Bar-----------------*/

.contactBar{
  --contactbar-height: 80px; 

  min-height:var(--contactbar-height); 
  background-color:var(--white-);
  width:100%; 
  margin-top:40px; 
}

.contactBar .walkingFeetWrapper
{
  margin-top:5px; 
}

.PhoneNumber a, .Mail a
{
  text-decoration:none; 
  color:var(--black-);
  float:left; 
  cursor:pointer; 
  font-size: 22px; 
  font-weight:300; 
  margin-left: 60px; 
  margin-right:60px; 
  line-height:var(--contactbar-height); 
}

.PhoneNumber a:hover, .Mail a:hover
{
  text-decoration:underline; 
}

/*--------------Footer------------------*/
footer
{
  background-color:var(--darkgrey-);
  min-height:200px; 
  width:100%;
  position:relative; 
  color:var(--white-);
}

.Footer_colorline
{
  background-color:var(--tetriary-color);
  height:15px; 
  width:100%; 
}

footer ul{
  margin:0; 
  list-style-type:none;
}

footer ul li{
  float:left; 
  width:200px; 
  margin-top:10px; 
  box-sizing:border-box; 
  font-weight:300; 
}

footer ul li:first-of-type, footer ul li:nth-of-type(2){
  margin-top:58px; 
}

footer ul li:nth-of-type(2n + 1)
{
  clear:left; 
}

.attribution{
  font-size:12px; 
  position:absolute; 
  bottom:10px; 
  left:25px; 
}

.attribution:last-of-type{
  right:25px;
  text-align:right;
}

footer .DinFodPlejer{
  float:right; 
  margin-top:20px;
}

footer .DinFodPlejer .textWrapper
{
  margin-right:20px; 
}

footer .DinFodPlejer IMG{
  border-radius:50%; 
  width:100px; 
  height:100px; 
  float:right; 
  margin-right:10px;
}

footer .DinFodPlejer h1, footer .DinFodPlejer h5{
  float:left; 
  margin-left:20px; 
  font-weight:300;
} 

footer .DinFodPlejer h1 span{
    margin-right:5px;
    display:block;
    float:left;  
}

footer .DinFodPlejer h5{
  margin-top:-10px; 
}

footer .DinFodPlejer h1 span::first-letter, footer .DinFodPlejer h5::first-letter{
  font-weight:500; 
}

footer a{
  color:var(--white-);
}

/*-----------Walking Feet----------------*/
.walkingFeetWrapper
{
  width:70px; 
  height:70px; 
  float:left;
  margin-right:20px; 
  position:relative; 
  overflow:hidden;
}

.walkingFeetWrapper::after
{
  content:"";
  background:var(--circle-gradient-white);
  position:absolute; 
  top:0; 
  left:0; 
  right:0; 
  bottom:0; 
  display:block;
  height:70px; 
  width:70px; 
}

.walkingFeetWrapper .foot{
  background-size:20px 20px; 
  width:10px; 
  height:20px; 
  background-image:url('feet.png');
  float:left; 
  clear:left;
  margin-right:3px; 
  animation:Fade-foot-in 1s ease-in; 
  -webkit-animation:Fade-Foot-In 1s ease-in forwards; 
  animation-delay:3.2s; 
  -webkit-animation-delay:3.2s; 
  opacity:0; 
}

.walkingFeetWrapper .foot:nth-of-type(6)
{
  animation-delay:0s;
  -webkit-animation-delay:0s; 
}

.walkingFeetWrapper .foot:nth-of-type(5)
{
  animation-delay:.6s; 
  -webkit-animation-delay:.6s; 
}

.walkingFeetWrapper .foot:nth-of-type(4)
{
  animation-delay:1.2s; 
  -webkit-animation-delay:1.2s; 
}

.walkingFeetWrapper .foot:nth-of-type(3)
{
  animation-delay:1.6s; 
  -webkit-animation-delay:1.6s; 
}

.walkingFeetWrapper .foot:nth-of-type(2)
{
  animation-delay:2s; 
  -webkit-animation-delay:2s; 
}

.walkingFeetWrapper .foot:nth-of-type(1)
{
  animation-delay:2.4s; 
  -webkit-animation-delay:2.4s; 
}

.walkingFeetWrapper .foot:nth-child(2n){
  background-image:url('feet.png');
  background-position:10px 0px; 
  clear:none;
  margin-top:10px; 
}

.walkingFeetWrapper_inner
{
  transform:rotate3d(0, 0, 1, 24deg);
  margin-left:20px; 
  margin-top:-5px; 
  overflow:hidden; 
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


@keyframes Fade-Foot-In {
  0% {
    opacity:0; 
    transform: scale3d(1.1, 1.1, 1.1);
  }
  30% {
    opacity:1; 
    transform: scale3d(1.05, 1.05, 1.05);
  }
  100% {
    opacity:1; 
    transform: scale3d(1, 1, 1);
  }
}

@keyframes Fade-Out {
  0% {
    opacity:1; 
  }
  100% {
    opacity:0; 
  }
}

.loadingScreen{
  background-color:var(--white-);
  position:absolute; 
  left:0; 
  right:0; 
  top:0; 
  bottom:0; 
  width:100%; 
  z-index:20; 
  opacity:0.9; 
  transition:opacity 0.2s ease-in-out; 
}

.loadingScreen.loaded{
  opacity:0; 
}
/*-----------Spinner------------*/
.lds-dual-ring {
  display: inline-block;
  width: 80px;
  height: 80px;
  position:absolute; 
  left:50%;
  top:200px; 
}
.lds-dual-ring:after {
  content: " ";
  display: block;
  width: 64px;
  height: 64px;
  margin: 8px;
  border-radius: 50%;
  border: 6px solid var(--tetriary-color);
  border-color: var(--tetriary-color) transparent var(--tetriary-color) transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}
@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/*--Responsive--*/
@media screen and (max-width: 961px)
{
  .contentWrapper{
    width:100%;
  }

  .Content{
    width:calc(100% - 320px);
  }
}

@media screen and (max-width: 741px)
{
  .Aside{
    display:block;
    clear:both;
    width:100%;
  }
  .Aside_Behandlinger{
    display:none;
  }
  .Content{
    width:100%;
  }
  .contactBar .Mail a, .contactBar .PhoneNumber a{
    font-size:16px; 
    margin-left:20px; 
  }

  footer .DinFodPlejer{
    margin-right:40px; 
  }
}

@media screen and (max-width: 481px)
{
  .nav ul li a{
    font-size:14px; 
    font-weight:400; 
  }
  .nav
  {
    padding-bottom:10px; 
  }

  .contactBar .Mail a, .contactBar .PhoneNumber a{
    font-size:16px; 
    margin-left:10px; 
    margin-right:10px; 
  }

  .attribution {
    font-size:10px; 
  }
}

@media screen and (max-width: 401px)
{
  .contactBar .Mail a, .contactBar .PhoneNumber a{
    font-size:16px; 
    margin-left:10px; 
    margin-right:10px; 
    height:30px; 
    line-height:30px;
    padding:5px;  
  }
}